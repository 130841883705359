<template>
    <div v-if="user" >
        <CryptoRecharge />
        <TransactionHistory />
    </div>
</template>
<script setup>
import CryptoRecharge from './CryptoRecharge.vue';
import TransactionHistory from './TransactionHistory.vue';
import { watchEffect } from 'vue';
// import { connectToWebSocket } from "@/stores/socket.store"

import { useAccountStore } from "@/stores"
import { storeToRefs } from 'pinia';
const accountStore = useAccountStore();
const { user } = storeToRefs(accountStore);
watchEffect(() => {
    if (user.value) {
        // connectToWebSocket(user.value.user_name)
    }
})
</script>