import { defineStore, storeToRefs } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAccountStore, useAlertStore } from '.';
import { updateLicenses } from './account.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
export const useProxyStore = defineStore('proxy', {
    state: () => ({
        proxyList: [],
        loading: false,
    }),
    actions: {
        async getProxyList() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.post(`${baseUrl}/proxy/my-list`);
                this.proxyList = res.proxyList;
                updateLicenses()
            }
            catch (error) {
                alertStore.error(error);
            }
            return
        },
        async updateAutoRotationTime(uuid, minute) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.post(`${baseUrl}/license/change-rotation-time`, { uuid, minute: minute === 0 ? -1 : minute });
                alertStore.success("You set successfully")
                return
            } catch (error) {
                alertStore.error(error)
            }
            return

        },
        async updateAutoExtend(uuid, extend_flag) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.post(`${baseUrl}/license/update-auto-extend`, { uuid, extend_flag });
                return true
            } catch (error) {
                alertStore.error(error)
            }
            return false

        },
        async rebootDevice(license) {
            const alertStore = useAlertStore()
            try {
                this.loading = true;
                const res = await fetchWrapper.post(`${baseUrl}/proxy/reboot-device`, { license });
                this.loading = false;
                alertStore.success(res.message)
            } catch (error) {
                this.loading = false;
                alertStore.error(error)
            }
            return

        },
        async updateUserAuthentication(license, username, ipAddress) {
            const alertStore = useAlertStore()
            try {
                const res = await fetchWrapper.post(`${baseUrl}/proxy/update-user-authentication`, { license, username, ipAddress });
                // this.proxyList = this.proxyList.map(proxy=>{
                //     if(proxy.uuid===license){
                //         return res.proxy
                //     }
                //     else{
                //         return proxy
                //     }
                // });
                updateLicenses()
                alertStore.success("Updated Auth")

            } catch (error) {
                alertStore.error(error)
            }
            return
        },
        async updateNote(license, note) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.post(`${baseUrl}/proxy/update-note`, { license, note });
                updateLicenses()
                alertStore.success("Updated Note")

            } catch (error) {
                alertStore.error(error)
            }
            return
        },
        async updateCoupon(license, couponCode) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.post(`${baseUrl}/proxy/update-coupon`, { license, couponCode });
                updateLicenses()
                alertStore.success("Updated Note")

            } catch (error) {
                alertStore.error(error)
            }
            return
        },
        async changeOS(uuid, osType) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.post(`${baseUrl}/proxy/change-os`, { uuid, osType });
                alertStore.success("Updated OS Spoofing")

            } catch (error) {
                alertStore.error(error)
            }
            return
        },
        async extendLicense(license, time_unit) {
            const alertStore = useAlertStore()
            try {
                const res = await fetchWrapper.post(`${baseUrl}/license/extend`, { license, time_unit });
                // this.proxyList = this.proxyList.map(proxy=>{
                //     if(proxy.uuid===license){
                //         return res.proxy
                //     }
                //     else{
                //         return proxy
                //     }
                // });
                const accountStore = useAccountStore()
                const account = storeToRefs(accountStore)
                account.user.value.balance = res.balance
                updateLicenses()
                alertStore.success("Extend Success")
            } catch (error) {
                alertStore.error(error)
            }
            return

        },
        async changeIp(license) {
            const alertStore = useAlertStore()
            try {
                await fetchWrapper.get(`${baseUrl}/proxy/change-ip?license=${license}`);
                return
            } catch (error) {
                alertStore.error(error)
            }
            return

        },
        async refreshIp(license) {
            const alertStore = useAlertStore()
            try {
                const res = await fetchWrapper.get(`${baseUrl}/proxy/refresh-ip?license=${license}`);
                return res.publicIp
            } catch (error) {
                alertStore.error(error)
            }
            return

        }
        // Other actions...
    },
});