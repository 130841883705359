import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '..';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;

export const useAdminCouponStore = defineStore('admin-coupon', {
    state: () => ({
        Coupons:[]
    }),
    actions: {
        async loadCoupons() {
            const alertStore = useAlertStore();
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/coupon/load-coupons`);
                this.Coupons = res.totalCoupons
            } catch (error) {
                alertStore.error(error);
            }
        },
        async createNewCoupon( data) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/coupon/create`, data);
                alertStore.success("New successfully added")

            } catch (error) {
                console.log(error)
                alertStore.error(error);
            }
        },
        async deleteCoupons(couponCodes) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/coupon/delete`, { couponCodes });
                alertStore.success("Delete successful")
            } catch (error) {
                alertStore.error(error);
            }
        },
        async updateCoupon(updateRuquest) {
            const alertStore = useAlertStore();
            try {
                await fetchWrapper.post(`${baseUrl}/admin/coupon/update`, updateRuquest );
                alertStore.success("Update successful")

            } catch (error) {
                alertStore.error(error);
            }
        },
        
    },
});