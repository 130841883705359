<template>
  <div class="flex flex-col">
    <div v-if="user.role === 'admin'" class="fixed right-[50px] top-[187px]">
      <v-btn icon="mdi-plus" size="small" style="outline: none;" @click="addFAQ" data-bs-toggle="modal"
        data-bs-target="#FAQAddModal" class="bg-dark text-light"></v-btn>
    </div>
    <div v-if="faqs.length > 0" class=" md:mx-[200px] mx-[30px] mt-10">
      <draggable v-model="faqs" item-key="order" :draggable="user.role === 'admin' ? false : true" animation="200" @end="onEnd">
        <template #item="{element}">
          <div class="flex md:flex-row flex-col">
            <div v-if="editableId === element.id && user.role === 'admin'" class="w-full my-[15px]">
              <v-text-field v-model="element.question" variant="outlined" density="compact" 
                        rounded="0"></v-text-field>
              <v-textarea v-model="element.answer" variant="outlined" density="compact" 
                              rounded="0"></v-textarea>
            </div>
            <div v-else class="handle faq-item w-full" @click="toggle(element.id)">
              <div  class="faq-question" >
                <div ><b>{{ element.question }}</b></div>
                <button class="toggle-button">{{ openFaqId === element.id ? '-' : '+' }}</button>
              </div>
              <transition name="fade">
                <div v-if="openFaqId === element.id" class="faq-answer">
                  <p>{{ element.answer }}</p>
                </div>
              </transition>
            </div>
            <div v-if="user.role == 'admin'" class="pt-10 flex flex-row">
              <div v-if="editableId === element.id" class=" p-10 cursor-pointer h-[35px]" @click="saveFAQ(element.id, element.question, element.answer)">Save</div>
              <div v-else class=" p-10 cursor-pointer h-[35px] " @click="editFAQ(element.id)" >Edit</div>
              <div class=" p-10 cursor-pointer h-[35px]" @click="deleteFAQ(element.id)" >Delete</div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
  </div>
  <div>
    <AddNewOneDialog @close="addNewOneDialogFlag = false" :count="faqs.length" @updated="updatedHandler" />
  </div>
  
</template>

<script setup>
import { ref, onMounted } from 'vue';
import draggable from 'vuedraggable';
import { storeToRefs } from 'pinia';
import { useFAQStore, useAccountStore } from "@/stores";
import AddNewOneDialog from "./AddNewOneDialog.vue";
const faqStore = useFAQStore();
const accountStore = useAccountStore();
const {faqs} = storeToRefs(faqStore);
const {user} = storeToRefs(accountStore);
let openFaqId = ref(null);        // Ref to store ID of the currently open FAQ
let editableId = ref(null);
const addNewOneDialogFlag = ref(false);

const updatedHandler = () => {
    addNewOneDialogFlag.value = false
}

const onEnd = async () => {
  faqs.value = faqs.value.map((item, index) => {
    return {...item, order: index+1}
  })
  await faqStore.setOrder(faqs.value)
}


const deleteFAQ = async (id) => {
  await faqStore.deleteFAQ(id);
}

const editFAQ = (id) => {
  editableId.value = id;
}

const saveFAQ = async (id, question, answer) => {
  editableId.value = 0;
  await faqStore.saveFAQ(id, question, answer)
}

const toggle = (id) => {
  // Toggling the open state of the FAQ
  openFaqId.value = openFaqId.value === id ? null : id;
};

const addFAQ = () => {
  addNewOneDialogFlag.value = true;
}
onMounted(async () => {
  // Load FAQs when the component is mounted
  await faqStore.getAll();
});
</script>

<style>
.v-textarea .v-field--no-label textarea, .v-textarea .v-field--active textarea {
  font-size: 12px;
}
.faq-page {
  font-family: Arial, sans-serif;
}
.center-heading {
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Add margin below the heading */
}

.faq-item {
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid;
}

.faq-question {
  cursor: pointer;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
}

.faq-answer {
  padding: 10px;
  border-radius: 5px;
}

.load-more-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block; /* Ensure the button is displayed as a block element */
  margin: 20px auto; /* Center the button horizontally */
}

.load-more-button:hover {
  background-color: #0056b3;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.faq-answer {
  overflow: hidden; /* Ensure content doesn't overflow */
}
.toggle-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5em;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



/* Define your scoped styles here */
</style>
