<template>
  <MainHeader v-if="user" />
  <MainSidebar v-if="user" />
  <ChatComponent v-if="user?.role === 'user' && name !== 'fella'" />
  <CustomAlert />
  <div class=" d-flex flex-column transition overflow-hidden bg-gray" :class="user ? 'main-content' : ' '" :style="{paddingTop: notificationMessage && user ? '165px' : ''}">
    <router-view />
    <!-- <MainFooter /> -->
  </div>
  
</template>

<script setup>
import { onMounted, onBeforeUnmount, watchEffect, ref } from "vue";
import stateStore from "./utils/store";
import { useHead } from '@vueuse/head';
import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
// import MainFooter from "./components/Layouts/MainFooter.vue";
import CustomAlert from "./components/CustomAlert.vue";
import ChatComponent from "./components/Customer/ChatComponent.vue"
import { useAccountStore } from "@/stores"
import { storeToRefs } from "pinia"
const stateStoreInstance = stateStore;
const accountStore = useAccountStore()
accountStore.getAccount();
accountStore.getMeta()
const { user, siteTitle, siteInfo, notificationMessage } = storeToRefs(accountStore)

const name = process.env.VUE_APP_VUE_NAME;

const setFavicon = () => {
  const favicon = document.createElement('link');
  favicon.rel = 'icon';

  if (name === 'proproxy') {
    favicon.href = '/logo.png'; 
  } else if (name === 'global') {
    favicon.href = '/logo1.png'; 
  } else if (name === 'heros') {
    favicon.href = '/logo2.png'; 
  } else if (name === 'test') {
    favicon.href = '/logo3.png'; 
  } else if (name === 'fella') {
    favicon.href = '/logo4.jpg'; 
  } else {
    favicon.href = '/logo3.png'; 
  }

  document.head.appendChild(favicon);
};

// Set meta tags dynamically

watchEffect(() => {
  setFavicon();
  useHead({
    title: siteTitle.value ? siteTitle.value : 'Proxy',
    meta: [
      { name: 'description', content: siteInfo.value ? siteInfo.value : '' },
      { property: 'og:title', content: siteTitle.value ? siteTitle.value : 'Proxy'},
      { property: 'og:description', content: siteInfo.value ? siteInfo.value : ''},
    ],
  });

})

watchEffect(() => {
  document.body.classList.add("bg-body-secondary");
    if (stateStoreInstance.open) {
      document.body.classList.remove("sidebar-show");
      document.body.classList.add("sidebar-hide");
    } else {
      document.body.classList.remove("sidebar-hide");
      document.body.classList.add("sidebar-show");

    }
});
</script>