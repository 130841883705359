import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAccountStore, useAlertStore } from '..';
// import { connectToWebSocket } from '../socket.store';

const baseUrl = `${process.env.VUE_APP_VUE_API_URL}`;
// const siteKey = process.env.VUE_RECAPTCHA_V2_SITE_KEY;

export const useAdminStatisticStore = defineStore('admin-statistic', {
    state: () => ({
        totalUsers:0,
        activeUsers:0,
        totalModems:0,
        pausedModems:0,
        totalProxys:0,
        availableProxys:0,
        totalLicenses:0,
        expiringLicenses:0
    }),
    actions: {
        async loadStatisticsInfo() {
            const alertStore = useAlertStore();
            const account = useAccountStore()
            if(account&&account.user.user_name)
                // connectToWebSocket(account.user.user_name)
            try {
                const res = await fetchWrapper.get(`${baseUrl}/admin/load-statistics`,);
                this.totalUsers = res.totalUsers
                this.activeUsers = res.activeUsers
                this.totalModems = res.totalModems
                this.pausedModems = res.pausedModems
                this.totalProxys = res.totalProxys
                this.availableProxys = res.availableProxys
                this.totalLicenses = res.totalLicenses
                this.expiringLicenses = res.expiringLicenses
            } catch (error) {
                alertStore.error(error);
            }
        },
    },
});

export const updateStatistics = () => {
    useAdminStatisticStore().loadStatisticsInfo()
}
